import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { courseAssignmentActions } from '../../actions';
import initialState from '../../initialState';

function auth(state = initialState.courseAssignment, actions) {
  switch (actions.type) {
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingDepartmentProgrammes: true,
        departmentContext: actions.context,
        selectedCourse: {},
        departmentProgrammeError: {},
      };
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_SUCCESS: {
      const { context, data } = actions;
      const { departmentProgrammes } = state;
      const contextData = { ...context, data };
      const findContextIndex = departmentProgrammes.findIndex(
        (currentContext) =>
          currentContext.department_id === context.department_id
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          departmentProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          departmentProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingDepartmentProgrammes: false,
      };
    }
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_ERROR:
      return {
        ...state,
        departmentProgrammeError: actions.error,
        gettingDepartmentProgrammes: false,
      };
    case courseAssignmentActions.SHOW_EDIT_RESULT_CATEGORIES_MODAL:
      return {
        ...state,
        showEditResultCategoriesModal: actions.payload,
      };
    case courseAssignmentActions.MAKING_RESULTS_PROVISIONAL_REQUEST:
      return {
        ...state,
        makingResultsProvisional: true,
        makeResultsProvisionalError: {},
      };
    case courseAssignmentActions.MAKING_RESULTS_PROVISIONAL_SUCCESS:
      return {
        ...state,
        makingResultsProvisional: false,
      };
    case courseAssignmentActions.MAKING_RESULTS_PROVISIONAL_ERROR:
      return {
        ...state,
        makingResultsProvisional: false,
        makeResultsProvisionalError: actions.error,
      };
    case courseAssignmentActions.GETTING_PROVISIONAL_RESULTS_REQUEST:
      return {
        ...state,
        facultyResultsContext: actions.context,
        gettingProvisionalResults: true,
        viewProvisionalResultsError: {},
      };
    case courseAssignmentActions.GETTING_PROVISIONAL_RESULTS_SUCCESS: {
      const { data, context } = actions;
      const { facultyReportResults } = state;

      const findContextIndex = findIndex(
        facultyReportResults,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.study_year_id === context.study_year_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id
      );

      let newState = state;

      const contextData = { ...data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          facultyReportResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          facultyReportResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingProvisionalResults: false,
      };
    }

    case courseAssignmentActions.GETTING_PROVISIONAL_RESULTS_ERROR:
      return {
        ...state,
        viewProvisionalResultsError: actions.error,
        gettingProvisionalResults: false,
      };
    case courseAssignmentActions.SET_PROVISIONAL_RESULTS_CONTEXT:
      return {
        ...state,
        facultyResultsContext: actions.context,
      };
    case courseAssignmentActions.SET_EDIT_RESULT_CATEGORY:
      return {
        ...state,
        resultCategoriesToEdit: actions.payload,
      };
    case courseAssignmentActions.EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST:
      return {
        ...state,
        editingCourseAssignmentResultCategories: true,
        editCourseAssignmentResultCategoriesError: {},
      };
    case courseAssignmentActions.EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_SUCCESS:
      return {
        ...state,
        editingCourseAssignmentResultCategories: false,
      };
    case courseAssignmentActions.EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_ERROR:
      return {
        ...state,
        editingCourseAssignmentResultCategories: false,
        editCourseAssignmentResultCategoriesError: actions.error,
      };
    case courseAssignmentActions.GET_SUBMITTED_RESULTS_REQUEST:
      return {
        ...state,
        gettingSubmittedResults: true,
        getSubmittedResultsError: {},
      };
    case courseAssignmentActions.GET_SUBMITTED_RESULTS_SUCCESS: {
      const { courseAssignmentId, data } = actions;
      const { submittedResults } = state;
      const contextData = { courseAssignmentId, data };

      const findContextIndex = findIndex(
        submittedResults,
        (currentContext) =>
          currentContext.courseAssignmentId === courseAssignmentId
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          submittedResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          submittedResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingSubmittedResults: false,
      };
    }
    case courseAssignmentActions.GET_SUBMITTED_RESULTS_ERROR:
      return {
        ...state,
        getSubmittedResultsError: actions.error,
        gettingSubmittedResults: false,
      };
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_REQUEST:
      return {
        ...state,
        gettingAssignmentCourse: true,
        assignmentContext: actions.context,
        selectedCourse: {},
        getAssignmentCourseError: {},
      };
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_SUCCESS: {
      const { context, data } = actions;
      const { assignmentCourses } = state;
      const contextData = { ...context, data };
      const findContextIndex = assignmentCourses.findIndex(
        (currentContext) =>
          currentContext.department_id === context.department_id &&
          currentContext.semester_id === context.semester_id &&
          currentContext.study_level_id === context.study_level_id &&
          currentContext.intake_id === context.intake_id &&
          currentContext.campus_id === context.campus_id &&
          currentContext.academic_year_id === context.academic_year_id
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          assignmentCourses: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          assignmentCourses: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingAssignmentCourse: false,
      };
    }
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_ERROR:
      return {
        ...state,
        getAssignmentError: actions.error,
        gettingAssignmentCourse: false,
      };

    case courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        gettingContextAssignments: true,
        contextAssignmentError: {},
      };
    case courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_SUCCESS: {
      const { data, context } = actions;
      const { contextAssignments } = state;

      const findContextIndex = findIndex(
        contextAssignments,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.study_year_id === context.study_year_id &&
          dataContext.department_id === context.department_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id &&
          dataContext.course_unit_id === context.course_unit_id
      );

      let newState = state;

      const contextData = { ...data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          contextAssignments: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          contextAssignments: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingContextAssignments: false,
      };
    }

    case courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_ERROR:
      return {
        ...state,
        contextAssignmentError: actions.error,
        gettingContextAssignments: false,
      };
    case courseAssignmentActions.GET_LECTURER_CATEGORIES_REQUEST:
      return {
        ...state,
        gettingLecturerCategories: true,
        lecturerCategoriesError: {},
        lecturerCategoriesContext: actions.context,
      };
    case courseAssignmentActions.GET_LECTURER_CATEGORIES_SUCCESS: {
      const { data, context } = actions;
      const { lecturerCategories } = state;

      const findContextIndex = findIndex(
        lecturerCategories,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          lecturerCategories: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          lecturerCategories: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingLecturerCategories: false,
      };
    }
    case courseAssignmentActions.GET_LECTURER_CATEGORIES_ERROR:
      return {
        ...state,
        gettingLecturerCategories: false,
        lecturerCategoriesError: actions.error,
      };
    case courseAssignmentActions.SET_LECTURER_CATEGORY_TO_DELETE:
      return {
        ...state,
        lecturerCategoryToDelete: actions.data,
      };
    case courseAssignmentActions.SET_SHOW_COPY_LECTURER_CATEGORY_FORM:
      return {
        ...state,
        showCopyLecturerCategoryForm: actions.show,
      };
    case courseAssignmentActions.SET_SHOW_UPLOAD_LECTURER_CATEGORIES:
      return {
        ...state,
        showUploadLecturerCategories: actions.show,
      };
    case courseAssignmentActions.SET_SHOW_LECTURER_CATEGORY_FORM:
      return {
        ...state,
        showLecturerCategoryForm: actions.show,
      };
    case courseAssignmentActions.SET_LECTURER_CATEGORIES_CONTEXT:
      return {
        ...state,
        lecturerCategoriesContext: actions.context,
      };
    case courseAssignmentActions.CREATE_LECTURER_CATEGORY_REQUEST:
      return {
        ...state,
        creatingLecturerCategory: true,
        createLecturerCategoryError: {},
      };
    case courseAssignmentActions.CREATE_LECTURER_CATEGORY_SUCCESS:
      return {
        ...state,
        creatingLecturerCategory: false,
      };
    case courseAssignmentActions.CREATE_LECTURER_CATEGORY_ERROR:
      return {
        ...state,
        creatingLecturerCategory: false,
        createLecturerCategoryError: actions.error,
      };
    case courseAssignmentActions.EDIT_LECTURER_CATEGORY_REQUEST:
      return {
        ...state,
        editingLecturerCategory: true,
        editLecturerCategoryError: {},
      };
    case courseAssignmentActions.EDIT_LECTURER_CATEGORY_SUCCESS:
      return {
        ...state,
        editingLecturerCategory: false,
      };
    case courseAssignmentActions.EDIT_LECTURER_CATEGORY_ERROR:
      return {
        ...state,
        editingLecturerCategory: false,
      };
    case courseAssignmentActions.SET_LECTURER_CATEGORY_TO_EDIT:
      return {
        ...state,
        lecturerCategoryToEdit: actions.data,
      };
    case courseAssignmentActions.DELETE_LECTURER_CATEGORY_REQUEST:
      return {
        ...state,
        deletingLecturerCategory: true,
        deleteLecturerCategoryError: {},
      };
    case courseAssignmentActions.DELETE_LECTURER_CATEGORY_SUCCESS:
      return {
        ...state,
        deletingLecturerCategory: false,
      };
    case courseAssignmentActions.DELETE_LECTURER_CATEGORY_ERROR:
      return {
        ...state,
        deletingLecturerCategory: false,
        deleteLecturerCategoryError: actions.error,
      };
    case courseAssignmentActions.COPY_LECTURER_CATEGORY_REQUEST:
      return {
        ...state,
        copyingLecturerCategories: true,
        copyLecturerCategoriesError: {},
      };
    case courseAssignmentActions.COPY_LECTURER_CATEGORY_SUCCESS:
      return {
        ...state,
        copyingLecturerCategories: false,
      };
    case courseAssignmentActions.COPY_LECTURER_CATEGORY_ERROR:
      return {
        ...state,
        copyingLecturerCategories: false,
        copyLecturerCategoriesError: actions.error,
      };
    case courseAssignmentActions.SET_COURSE_ASSIGNMENT_GROUP_TO_EDIT:
      return {
        ...state,
        courseAssignmentGroupToEdit: actions.data,
      };
    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_GROUP_REQUEST:
      return {
        ...state,
        editingCourseAssignmentGroups: true,
        editCourseAssignmentGroupsError: {},
      };
    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_GROUP_SUCCESS:
      return {
        ...state,
        editingCourseAssignmentGroups: false,
      };
    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_GROUP_ERROR:
      return {
        ...state,
        editingCourseAssignmentGroups: false,
        editCourseAssignmentGroupsError: actions.error,
      };
    case courseAssignmentActions.SET_COURSE_ASSIGNMENT_GROUP_TO_DELETE:
      return {
        ...state,
        courseAssignmentGroupToDelete: actions.data,
      };
    case courseAssignmentActions.DELETE_COURSE_ASSIGNMENT_GROUP_REQUEST:
      return {
        ...state,
        deletingCourseAssignmentGroup: true,
        deleteCourseAssignmentGroupError: {},
      };
    case courseAssignmentActions.DELETE_COURSE_ASSIGNMENT_GROUP_SUCCESS:
      return {
        ...state,
        deletingCourseAssignmentGroup: false,
      };
    case courseAssignmentActions.DELETE_COURSE_ASSIGNMENT_GROUP_ERROR:
      return {
        ...state,
        deletingCourseAssignmentGroup: false,
        deleteCourseAssignmentGroupError: actions.error,
      };
    case courseAssignmentActions.GET_HOD_STATISTICS_REQUEST:
      return {
        ...state,
        hodStatisticsContext: actions.context,
        gettingHODStatistics: true,
        hodStatisticsError: {},
      };
    case courseAssignmentActions.GET_HOD_STATISTICS_SUCCESS: {
      const { data, context } = actions;
      const { hodStatistics } = state;

      const findContextIndex = findIndex(
        hodStatistics,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.study_year_ids === context.study_year_ids &&
          dataContext.department_id === context.department_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          hodStatistics: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          hodStatistics: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingHODStatistics: false,
      };
    }

    case courseAssignmentActions.GET_HOD_STATISTICS_ERROR:
      return {
        ...state,
        gettingHODStatistics: false,
        hodStatisticsError: actions.error,
      };

    case courseAssignmentActions.GET_DEPARTMENT_APPROVAL_RESULTS_REQUEST:
      return {
        ...state,
        departmentApprovalContext: actions.context,
        gettingDepartmentApprovalResults: true,
        departmentApprovalResultsError: {},
      };
    case courseAssignmentActions.GET_DEPARTMENT_APPROVAL_RESULTS_SUCCESS: {
      const { data, context } = actions;
      const { departmentApprovalResults } = state;

      const findContextIndex = findIndex(
        departmentApprovalResults,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.study_year_ids === context.study_year_ids &&
          dataContext.department_id === context.department_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          departmentApprovalResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          departmentApprovalResults: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingDepartmentApprovalResults: false,
      };
    }

    case courseAssignmentActions.GET_DEPARTMENT_APPROVAL_RESULTS_ERROR:
      return {
        ...state,
        gettingDepartmentApprovalResults: false,
        departmentApprovalResultsError: actions.error,
      };

    case courseAssignmentActions.SET_SHOW_VIEW_COURSE_ASSIGNMENT_ANALYTICS_RESULTS_MODAL:
      return {
        ...state,
        showViewAnalyticsResults: actions.show,
        analyticsResults: actions.data,
      };

    case courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_REQUEST:
      return {
        ...state,
        assigningLecturers: true,
        assignError: {},
      };
    case courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_SUCCESS:
      return {
        ...state,
        assignSuccess: actions.data,
        assigningLecturers: false,
      };
    case courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_ERROR:
      return {
        ...state,
        assignError: actions.error,
        assigningLecturers: false,
      };

    case courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        addingLecturer: true,
        addLecturerError: {},
      };
    case courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        addingLecturer: false,
      };
    case courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_ERROR:
      return {
        ...state,
        addLecturerError: actions.error,
        addingLecturer: false,
      };

    case courseAssignmentActions.ADD_GROUP_TO_COURSE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        addingGroups: true,
        addGroupError: {},
      };
    case courseAssignmentActions.ADD_GROUP_TO_COURSE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        addingGroups: false,
      };
    case courseAssignmentActions.ADD_GROUP_TO_COURSE_ASSIGNMENT_ERROR:
      return {
        ...state,
        addGroupError: actions.error,
        addingGroups: false,
      };

    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST:
      return {
        ...state,
        editingLecturer: true,
        editLecturerError: {},
      };
    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_SUCCESS:
      return {
        ...state,
        editingLecturer: false,
      };
    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_ERROR:
      return {
        ...state,
        editLecturerError: actions.error,
        editingLecturer: false,
      };

    case courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST:
      return {
        ...state,
        removingLecturers: true,
        removeLecturerError: {},
      };
    case courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        removingLecturers: false,
      };
    case courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_ERROR:
      return {
        ...state,
        removeLecturerError: actions.error,
        removingLecturers: false,
      };

    case courseAssignmentActions.SET_SELECTED_ASSIGNMENT_COURSE:
      return {
        ...state,
        selectedCourse: actions.course,
      };

    case courseAssignmentActions.SET_SELECTED_ASSIGNMENT_LECTURERS:
      return {
        ...state,
        selectedLecturers: actions.data,
      };

    case courseAssignmentActions.SET_SHOW_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: actions.data,
      };

    case courseAssignmentActions.SET_PROGRAMME_CONTEXT_ID:
      return {
        ...state,
        programmeContextId: actions.id,
      };

    case courseAssignmentActions.SET_SHOW_ADD_LECTURER_MODAL:
      return {
        ...state,
        showAddLecturerModal: actions.data,
      };

    case courseAssignmentActions.SET_SHOW_ADD_GROUP_MODAL:
      return {
        ...state,
        showAddGroupModal: actions.data,
      };

    case courseAssignmentActions.SET_EDIT_ASSIGNMENT_LECTURER:
      return {
        ...state,
        editing: actions.editing,
        dataToEdit: actions.dataToEdit,
      };
    case courseAssignmentActions.RETURN_RESULT_REQUEST:
      return {
        ...state,
        unSubmittingResults: true,
        unSubmitResultsError: {},
      };
    case courseAssignmentActions.RETURN_RESULT_SUCCESS:
      return {
        ...state,
        unSubmittingResults: false,
      };
    case courseAssignmentActions.RETURN_RESULT_ERROR:
      return {
        ...state,
        unSubmitResultsError: actions.error,
        unSubmittingResults: false,
      };
    case courseAssignmentActions.SET_SHOW_RETURN_RESULT_MODAL:
      return {
        ...state,
        unSubmitResultModal: actions.data,
      };
    case courseAssignmentActions.SET_SHOW_VIEW_SUBMITTED_RESULT_MODAL:
      return {
        ...state,
        showViewSubmittedResult: actions.data,
      };
    case courseAssignmentActions.GET_FACULTY_ANALYTICS_REQUEST:
      return {
        ...state,
        gettingFacultyAnalytics: true,
        facultyAnalyticsError: {},
      };
    case courseAssignmentActions.GET_FACULTY_ANALYTICS_SUCCESS: {
      const { data, context } = actions;
      const { facultyAnalytics } = state;

      const findContextIndex = findIndex(
        facultyAnalytics,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.faculty_id === context.faculty_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          facultyAnalytics: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          facultyAnalytics: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingFacultyAnalytics: false,
      };
    }
    case courseAssignmentActions.GET_FACULTY_ANALYTICS_ERROR:
      return {
        ...state,
        gettingFacultyAnalytics: false,
        facultyAnalyticsError: actions.error,
      };
    case courseAssignmentActions.SET_PROGRAMME_RESULTS_REPORT_CONTEXT: {
      return {
        ...state,
        programmeResultsReportContext: actions.context,
      };
    }
    case courseAssignmentActions.GET_PROGRAMME_RESULTS_REPORT_REQUEST:
      return {
        ...state,
        gettingProgrammeResultsReport: true,
        programmeResultsReportError: {},
        programmeResultsReportContext: actions.context,
      };
    case courseAssignmentActions.GET_PROGRAMME_RESULTS_REPORT_SUCCESS: {
      const { data, context } = actions;
      const { programmeResultsReport } = state;

      const findContextIndex = findIndex(
        programmeResultsReport,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id &&
          dataContext.department_id === context.department_id &&
          dataContext.submission_level === context.submission_level
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          programmeResultsReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          programmeResultsReport: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingProgrammeResultsReport: false,
      };
    }
    case courseAssignmentActions.GET_PROGRAMME_RESULTS_REPORT_ERROR:
      return {
        ...state,
        gettingProgrammeResultsReport: false,
        programmeResultsReportError: actions.error,
      };
    case courseAssignmentActions.GET_QUATEC_RESULTS_REQUEST:
      return {
        ...state,
        gettingQuatecResults: true,
        quatecResultsError: {},
      };
    case courseAssignmentActions.GET_QUATEC_RESULTS_SUCCESS: {
      const { data, context } = actions;
      const { quatecResults } = state;

      const findContextIndex = findIndex(
        quatecResults,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id &&
          dataContext.faculty_id === context.faculty_id &&
          dataContext.submission_level === context.submission_level
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          quatecResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          quatecResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingQuatecResults: false,
      };
    }
    case courseAssignmentActions.GET_QUATEC_RESULTS_ERROR:
      return {
        ...state,
        gettingQuatecResults: false,
        quatecResultsError: actions.error,
      };
    case courseAssignmentActions.SET_QUATEC_RESULTS_CONTEXT:
      return {
        ...state,
        quatecResultsContext: actions.context,
      };
    case courseAssignmentActions.GET_QUATEC_ANALYTICS_REQUEST:
      return {
        ...state,
        gettingQuatecAnalytics: true,
        quatecAnalyticsError: {},
      };
    case courseAssignmentActions.GET_QUATEC_ANALYTICS_SUCCESS: {
      const { data, context } = actions;
      const { quatecAnalytics } = state;

      const findContextIndex = findIndex(
        quatecAnalytics,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.faculty_id === context.faculty_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          quatecAnalytics: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          quatecAnalytics: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingQuatecAnalytics: false,
      };
    }
    case courseAssignmentActions.GET_QUATEC_ANALYTICS_ERROR:
      return {
        ...state,
        gettingQuatecAnalytics: false,
        quatecAnalyticsError: actions.error,
      };
    case courseAssignmentActions.SET_SUBMITTED_RESULT_TO_VIEW:
      return {
        ...state,
        resultToView: actions.data,
        showViewSubmittedResultModal: actions.show,
      };
    case courseAssignmentActions.SUBMIT_RESULTS_REQUEST:
      return {
        ...state,
        submittingResults: true,
        submitResultsError: {},
      };
    case courseAssignmentActions.SUBMIT_RESULTS_SUCCESS:
      return {
        ...state,
        submittingResults: false,
      };
    case courseAssignmentActions.SUBMIT_RESULTS_ERROR:
      return {
        ...state,
        submittingResults: false,
        submitResultsError: actions.error,
      };
    case courseAssignmentActions.REVERSE_PUBLISHED_RESULTS_REQUEST:
      return {
        ...state,
        reversingPublishedResults: true,
        reversePublishedResultsError: {},
      };
    case courseAssignmentActions.REVERSE_PUBLISHED_RESULTS_SUCCESS:
      return {
        ...state,
        reversingPublishedResults: false,
      };
    case courseAssignmentActions.REVERSE_PUBLISHED_RESULTS_ERROR:
      return {
        ...state,
        reversingPublishedResults: false,
        reversePublishedResultsError: actions.error,
      };
    case courseAssignmentActions.SUBMIT_RESULTS_TO_FACULTY_REQUEST:
      return {
        ...state,
        submittingResultsToFaculty: true,
        submitResultsToFacultyError: {},
      };
    case courseAssignmentActions.SUBMIT_RESULTS_TO_FACULTY_SUCCESS:
      return {
        ...state,
        submittingResultsToFaculty: false,
      };

    case courseAssignmentActions.SUBMIT_RESULTS_TO_FACULTY_ERROR:
      return {
        ...state,
        submittingResultsToFaculty: false,
        submitResultsToFacultyError: actions.error,
      };
    case courseAssignmentActions.GET_SENATE_RESULTS_REQUEST:
      return {
        ...state,
        gettingSenateResults: true,
        senateResultsError: {},
      };
    case courseAssignmentActions.SET_SENATE_RESULTS_CONTEXT:
      return {
        ...state,
        senateResultsContext: actions.context,
      };
    case courseAssignmentActions.GET_SENATE_RESULTS_SUCCESS: {
      const { data, context } = actions;
      const { senateResults } = state;

      const findContextIndex = findIndex(
        senateResults,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id &&
          dataContext.faculty_id === context.faculty_id &&
          dataContext.submission_level === context.submission_level &&
          dataContext.programme_id === context.programme_id
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          senateResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          senateResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingSenateResults: false,
      };
    }

    case courseAssignmentActions.GET_SENATE_RESULTS_ERROR:
      return {
        ...state,
        gettingSenateResults: false,
        senateResultsError: actions.error,
      };
    case courseAssignmentActions.SUBMIT_RESULTS_TO_SENATE_REQUEST:
      return {
        ...state,
        submittingResultsToSenate: true,
        submitResultsToSenateError: {},
      };
    case courseAssignmentActions.SUBMIT_RESULTS_TO_SENATE_SUCCESS:
      return {
        ...state,
        submittingResultsToSenate: false,
      };
    case courseAssignmentActions.SUBMIT_RESULTS_TO_SENATE_ERROR:
      return {
        ...state,
        submittingResultsToSenate: false,
        submitResultsToSenateError: actions.error,
      };
    case courseAssignmentActions.SET_ACTIVE_COURSE_ASSIGNMENT_HIERARCHY:
      return {
        ...state,
        activeApprovalHierarchy: actions.data,
      };
    case courseAssignmentActions.GET_COURSE_UNIT_RESULT_APPROVALS_REQUEST:
      return {
        ...state,
        gettingCourseUnitResultApprovals: true,
        getCourseUnitResultApprovalsError: {},
      };
    case courseAssignmentActions.GET_COURSE_UNIT_RESULT_APPROVALS_SUCCESS: {
      const { data, context } = actions;
      const { courseUnitResultApprovals } = state;

      const findContextIndex = findIndex(
        courseUnitResultApprovals,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id &&
          dataContext.study_year_id === context.study_year_id &&
          dataContext.submission_level === context.submission_level &&
          dataContext.course_unit_id === context.course_unit_id
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          courseUnitResultApprovals: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          courseUnitResultApprovals: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingCourseUnitResultApprovals: false,
      };
    }
    case courseAssignmentActions.GET_COURSE_UNIT_RESULT_APPROVALS_ERROR:
      return {
        ...state,
        gettingCourseUnitResultApprovals: false,
        getCourseUnitResultApprovalsError: actions.error,
      };
    case courseAssignmentActions.GET_PROGRAMME_RESULT_APPROVALS_REQUEST:
      return {
        ...state,
        gettingProgrammeResultApprovals: true,
        getProgrammeResultApprovalsError: {},
      };
    case courseAssignmentActions.GET_PROGRAMME_RESULT_APPROVALS_SUCCESS: {
      const { data, context } = actions;
      const { programmeResultApprovals } = state;

      const findContextIndex = findIndex(
        programmeResultApprovals,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id &&
          dataContext.study_year_id === context.study_year_id &&
          dataContext.submission_level === context.submission_level &&
          dataContext.programme_id === context.programme_id
      );

      let newState = state;

      const contextData = { data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          programmeResultApprovals: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          programmeResultApprovals: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingProgrammeResultApprovals: false,
      };
    }
    case courseAssignmentActions.GET_PROGRAMME_RESULT_APPROVALS_ERROR:
      return {
        ...state,
        gettingProgrammeResultApprovals: false,
        getProgrammeResultApprovalsError: actions.error,
      };

    default:
      return state;
  }
}

export default auth;
