import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { courseAssignmentActions } from '../../actions';

function* getDepartmentProgrammes(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/department-programmes',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getContextAssignments(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment/course-assignments',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_ERROR,
      error: error.data,
    });
  }
}

function* assignLecturersToCourse(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context: actions.context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_ADD_LECTURER_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_ERROR,
      error: error.data,
    });
  }
}

function* getAssignmentCourses(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/courses',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_ERROR,
      error: error.data,
    });
  }
}

function* editCourseAssignmentResultCategories(actions) {
  try {
    const { context, data } = actions;

    const response = yield axios({
      url: `/course-assignment/edit-result-categories`,
      method: 'PUT',
      data,
    });

    yield put({
      type: courseAssignmentActions.EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getContextAssignments(context));
    yield put(courseAssignmentActions.setShowEditResultCategoriesModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_ERROR,
      error: error.data,
    });
  }
}

function* addLecturersToAssignment(actions) {
  try {
    const { context, data, assignmentId } = actions;
    const response = yield axios({
      url: `/course-assignment/add-lecturer/${assignmentId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_ADD_LECTURER_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* addGroupToAssignment(actions) {
  try {
    const { context, data, assignmentId } = actions;
    const response = yield axios({
      url: `/course-assignment/add-groups/${assignmentId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: courseAssignmentActions.ADD_GROUP_TO_COURSE_ASSIGNMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_ADD_GROUP_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.ADD_GROUP_TO_COURSE_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* editAssignmentLecturer(actions) {
  try {
    const { context, data, assignmentLectureId } = actions;
    const response = yield axios({
      url: `/course-assignment/edit-lecturer/${assignmentLectureId}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_ADD_LECTURER_MODAL,
      data: false,
    });
    yield put({
      type: courseAssignmentActions.SET_EDIT_ASSIGNMENT_LECTURER,
      editing: false,
      dataToEdit: {},
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_ERROR,
      error: error.data,
    });
  }
}

function* removeLecturersFromAssignment(actions) {
  try {
    const { context, data } = actions;
    const response = yield axios({
      url: `/course-assignment/remove-lecturers`,
      method: 'DELETE',
      data,
      params: context,
    });
    yield put({
      type: courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_DELETE_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* getDepartmentSubmittedResults(actions) {
  try {
    const { courseAssignmentId, params } = actions;
    const response = yield axios({
      url: `/course-assignment/department-submitted-results`,
      method: 'GET',
      params,
    });
    yield put({
      type: courseAssignmentActions.GET_SUBMITTED_RESULTS_SUCCESS,
      data: response.data,
      courseAssignmentId,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_SUBMITTED_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* unSubmitResults(actions) {
  try {
    const { data, params } = actions;

    const response = yield axios({
      url: `/course-assignment/return-results`,
      method: 'PATCH',
      params,
      data,
    });

    yield put({
      type: courseAssignmentActions.RETURN_RESULT_SUCCESS,
      data: response.data,
    });
    if (data.submission_level === 'DEPARTMENT') {
      if (params.usage === 'department-results-approval') {
        yield put(courseAssignmentActions.getDepartmentApprovalResults(params));
      } else
        yield put(
          courseAssignmentActions.getSubmittedResults(
            params?.course_assignment_id,
            params
          )
        );
    } else if (data.submission_level === 'FACULTY BOARD') {
      yield put(courseAssignmentActions.getFacultyResults(params));
    } else if (data.submission_level === 'QUATEC') {
      yield put(courseAssignmentActions.getQuatecResults(params));
    }
    yield put(courseAssignmentActions.setShowUnSubmitResultModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.RETURN_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* reversePublishedResults(actions) {
  try {
    const { data, context } = actions;

    const response = yield axios({
      url: `/course-assignment/reverse-published-results`,
      method: 'PATCH',
      params: context,
      data,
    });

    yield put({
      type: courseAssignmentActions.REVERSE_PUBLISHED_RESULTS_SUCCESS,
      data: response.data,
    });

    yield put(courseAssignmentActions.getSenateResults(context));
    yield put(courseAssignmentActions.setShowUnSubmitResultModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.REVERSE_PUBLISHED_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* setResultsProvisionalStatus(actions) {
  try {
    const { data, context } = actions;
    const response = yield axios({
      url: '/course-assignment/set-provisional-status',
      method: 'PATCH',
      data,
      params: {
        course_unit_id: context.course_unit_id,
      },
    });
    yield put({
      type: courseAssignmentActions.MAKING_RESULTS_PROVISIONAL_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getFacultyResults(context));
    yield put(courseAssignmentActions.setShowUnSubmitResultModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.MAKING_RESULTS_PROVISIONAL_ERROR,
      error: error.data,
    });
  }
}

function* getFacultyResults(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/submitted-results',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GETTING_PROVISIONAL_RESULTS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GETTING_PROVISIONAL_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getSenateResults(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/submitted-results',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_SENATE_RESULTS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_SENATE_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* submitResultsToSenate(actions) {
  try {
    const { data, context } = actions;
    const response = yield axios({
      url: '/course-assignment/submit-senate-results',
      method: 'PATCH',
      data,
    });
    yield put({
      type: courseAssignmentActions.SUBMIT_RESULTS_TO_SENATE_SUCCESS,
      data: response.data,
    });

    yield put(courseAssignmentActions.getQuatecResults(context));
    yield put(courseAssignmentActions.setShowUnSubmitResultModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.SUBMIT_RESULTS_TO_SENATE_ERROR,
      error: error.data,
    });
  }
}

function* getHODStatistics(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/hod-statistics',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_HOD_STATISTICS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_HOD_STATISTICS_ERROR,
      error: error.data,
    });
  }
}

function* getDepartmentApprovalResults(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/hod-statistics',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_DEPARTMENT_APPROVAL_RESULTS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_DEPARTMENT_APPROVAL_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getFacultyAnalytics(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/faculty-results-analytics',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_FACULTY_ANALYTICS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_FACULTY_ANALYTICS_ERROR,
      error: error.data,
    });
  }
}

function* getQuatecResults(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/submitted-results',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_QUATEC_RESULTS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_QUATEC_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammeResultsReport(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/submitted-results',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_PROGRAMME_RESULTS_REPORT_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_PROGRAMME_RESULTS_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getQuatecAnalytics(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/faculty-analytics',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_QUATEC_ANALYTICS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_QUATEC_ANALYTICS_ERROR,
      error: error.data,
    });
  }
}

function* submitResultsToFaculty(actions) {
  try {
    const { data, context } = actions;
    const response = yield axios({
      url: '/course-assignment/submit-faculty-results',
      method: 'PATCH',
      data,
    });
    yield put({
      type: courseAssignmentActions.SUBMIT_RESULTS_TO_FACULTY_SUCCESS,
      data: response.data,
    });
    if (context.usage === 'department-results-approval') {
      yield put(courseAssignmentActions.getDepartmentApprovalResults(context));
    } else
      yield put(
        courseAssignmentActions.getSubmittedResults(
          context?.course_assignment_id,
          context
        )
      );
    yield put(courseAssignmentActions.setShowUnSubmitResultModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.SUBMIT_RESULTS_TO_FACULTY_ERROR,
      error: error.data,
    });
  }
}

function* submitResults({ data, context }) {
  try {
    const response = yield axios({
      url: '/course-assignment/submit-results',
      method: 'PATCH',
      data,
      params: context,
    });
    yield put({
      type: courseAssignmentActions.SUBMIT_RESULTS_SUCCESS,
      data: response.data,
    });
    if (data.submission_level === 'DEPARTMENT') {
      yield put(
        courseAssignmentActions.getSubmittedResults(
          data?.course_assignment_id,
          context
        )
      );
    } else if (data.submission_level === 'FACULTY BOARD') {
      yield put(courseAssignmentActions.getFacultyResults(context));
    } else if (data.submission_level === 'QUATEC') {
      yield put(courseAssignmentActions.getQuatecResults(context));
    } else if (data.submission_level === 'SENATE') {
      yield put(courseAssignmentActions.getSenateResults(context));
    }
    yield put(courseAssignmentActions.setShowUnSubmitResultModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.SUBMIT_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getCourseUnitResultApprovals({ context }) {
  try {
    const response = yield axios({
      url: '/course-assignment/course-unit-result-approvals',
      params: context,
    });

    yield put({
      type: courseAssignmentActions.GET_COURSE_UNIT_RESULT_APPROVALS_SUCCESS,
      context,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_COURSE_UNIT_RESULT_APPROVALS_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammeResultApprovals({ context }) {
  try {
    const response = yield axios({
      url: '/course-assignment/programme-result-approvals',
      params: context,
    });

    yield put({
      type: courseAssignmentActions.GET_PROGRAMME_RESULT_APPROVALS_SUCCESS,
      context,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_PROGRAMME_RESULT_APPROVALS_ERROR,
      error: error.data,
    });
  }
}

function* getLecturerCategoriesByAcademicYear({ context }) {
  try {
    const response = yield axios({
      url: `/course-assignment/lecturer-categories/by-academic-year/${context.academic_year_id}`,
      method: 'GET',
    });
    yield put({
      type: courseAssignmentActions.GET_LECTURER_CATEGORIES_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_LECTURER_CATEGORIES_ERROR,
      error: error.data,
    });
  }
}

function* createLecturerCategory({ data, context }) {
  try {
    const response = yield axios({
      url: '/course-assignment/lecturer-categories',
      method: 'POST',
      data,
    });
    yield put({
      type: courseAssignmentActions.CREATE_LECTURER_CATEGORY_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getLecturerCategories(context));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.CREATE_LECTURER_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* editLecturerCategory({ data, context, id }) {
  try {
    const response = yield axios({
      url: `/course-assignment/lecturer-categories/${id}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: courseAssignmentActions.EDIT_LECTURER_CATEGORY_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getLecturerCategories(context));
    yield put(courseAssignmentActions.setShowLecturerCategoryForm(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.EDIT_LECTURER_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* deleteLecturerCategory({ id, context }) {
  try {
    const response = yield axios({
      url: `/course-assignment/lecturer-categories/${id}`,
      method: 'DELETE',
    });
    yield put({
      type: courseAssignmentActions.DELETE_LECTURER_CATEGORY_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getLecturerCategories(context));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.DELETE_LECTURER_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* copyLecturerCategories({ data, context }) {
  try {
    const response = yield axios({
      url: '/course-assignment/lecturer-categories/copy-lecturer-categories',
      method: 'POST',
      data,
    });
    yield put({
      type: courseAssignmentActions.COPY_LECTURER_CATEGORY_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getLecturerCategories(context));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.COPY_LECTURER_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* editCourseAssignmentGroup(actions) {
  try {
    const response = yield axios({
      url: `/course-assignment/groups/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_GROUP_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getContextAssignments(actions.context));
    yield put(courseAssignmentActions.setAddGroupModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_GROUP_ERROR,
      error: error.data,
    });
  }
}

function* deleteCourseAssignmentGroup(actions) {
  try {
    const response = yield axios({
      url: `/course-assignment/groups/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: courseAssignmentActions.DELETE_COURSE_ASSIGNMENT_GROUP_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getContextAssignments(actions.context));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.DELETE_COURSE_ASSIGNMENT_GROUP_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteCourseAssignmentGroup() {
  yield takeLatest(
    courseAssignmentActions.DELETE_COURSE_ASSIGNMENT_GROUP_REQUEST,
    deleteCourseAssignmentGroup
  );
}

function* watchEditCourseAssignmentGroup() {
  yield takeLatest(
    courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_GROUP_REQUEST,
    editCourseAssignmentGroup
  );
}

function* watchCreateLecturerCategory() {
  yield takeLatest(
    courseAssignmentActions.CREATE_LECTURER_CATEGORY_REQUEST,
    createLecturerCategory
  );
}

function* watchEditLecturerCategory() {
  yield takeLatest(
    courseAssignmentActions.EDIT_LECTURER_CATEGORY_REQUEST,
    editLecturerCategory
  );
}

function* watchDeleteLecturerCategory() {
  yield takeLatest(
    courseAssignmentActions.DELETE_LECTURER_CATEGORY_REQUEST,
    deleteLecturerCategory
  );
}

function* watchCopyLecturerCategories() {
  yield takeLatest(
    courseAssignmentActions.COPY_LECTURER_CATEGORY_REQUEST,
    copyLecturerCategories
  );
}

function* watchGetLecturerCategoriesByAcademicYear() {
  yield takeLatest(
    courseAssignmentActions.GET_LECTURER_CATEGORIES_REQUEST,
    getLecturerCategoriesByAcademicYear
  );
}

function* watchGetProgrammeResultApprovals() {
  yield takeLatest(
    courseAssignmentActions.GET_PROGRAMME_RESULT_APPROVALS_REQUEST,
    getProgrammeResultApprovals
  );
}

function* watchGetProgrammeResultsReport() {
  yield takeLatest(
    courseAssignmentActions.GET_PROGRAMME_RESULTS_REPORT_REQUEST,
    getProgrammeResultsReport
  );
}

function* watchReversePublishedResults() {
  yield takeLatest(
    courseAssignmentActions.REVERSE_PUBLISHED_RESULTS_REQUEST,
    reversePublishedResults
  );
}

function* watchGetCourseUnitResultApprovals() {
  yield takeLatest(
    courseAssignmentActions.GET_COURSE_UNIT_RESULT_APPROVALS_REQUEST,
    getCourseUnitResultApprovals
  );
}

function* watchGetSenateResults() {
  yield takeLatest(
    courseAssignmentActions.GET_SENATE_RESULTS_REQUEST,
    getSenateResults
  );
}

function* watchSubmitResultToFaculty() {
  yield takeLatest(
    courseAssignmentActions.SUBMIT_RESULTS_TO_FACULTY_REQUEST,
    submitResultsToFaculty
  );
}

function* watchSubmitResultsToSenate() {
  yield takeLatest(
    courseAssignmentActions.SUBMIT_RESULTS_TO_SENATE_REQUEST,
    submitResultsToSenate
  );
}

function* watchSubmitResultsToQuatec() {
  yield takeLatest(
    courseAssignmentActions.SUBMIT_RESULTS_REQUEST,
    submitResults
  );
}

function* watchGetQuatecResults() {
  yield takeLatest(
    courseAssignmentActions.GET_QUATEC_RESULTS_REQUEST,
    getQuatecResults
  );
}

function* watchGetQuatecAnalytics() {
  yield takeLatest(
    courseAssignmentActions.GET_QUATEC_ANALYTICS_REQUEST,
    getQuatecAnalytics
  );
}

function* watchGetFacultyAnalytics() {
  yield takeLatest(
    courseAssignmentActions.GET_FACULTY_ANALYTICS_REQUEST,
    getFacultyAnalytics
  );
}

function* watchGetHODStatistics() {
  yield takeLatest(
    courseAssignmentActions.GET_HOD_STATISTICS_REQUEST,
    getHODStatistics
  );
}
function* watchGetDepartmentApprovalResults() {
  yield takeLatest(
    courseAssignmentActions.GET_DEPARTMENT_APPROVAL_RESULTS_REQUEST,
    getDepartmentApprovalResults
  );
}

function* watchFetchProvisionalResults() {
  yield takeLatest(
    courseAssignmentActions.GETTING_PROVISIONAL_RESULTS_REQUEST,
    getFacultyResults
  );
}

function* watchMakeResultsProvisional() {
  yield takeLatest(
    courseAssignmentActions.MAKING_RESULTS_PROVISIONAL_REQUEST,
    setResultsProvisionalStatus
  );
}

function* watchUnSubmitResults() {
  yield takeLatest(
    courseAssignmentActions.RETURN_RESULT_REQUEST,
    unSubmitResults
  );
}

function* watchGetSubmittedResults() {
  yield takeLatest(
    courseAssignmentActions.GET_SUBMITTED_RESULTS_REQUEST,
    getDepartmentSubmittedResults
  );
}

function* watchGetDepartmentProgrammes() {
  yield takeLatest(
    courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST,
    getDepartmentProgrammes
  );
}

function* watchGetContextAssignments() {
  yield takeLatest(
    courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
    getContextAssignments
  );
}

function* watchAssignLecturersToCourse() {
  yield takeLatest(
    courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_REQUEST,
    assignLecturersToCourse
  );
}

function* watchAddLecturersToAssignment() {
  yield takeLatest(
    courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST,
    addLecturersToAssignment
  );
}

function* watchAddGroupToAssignment() {
  yield takeLatest(
    courseAssignmentActions.ADD_GROUP_TO_COURSE_ASSIGNMENT_REQUEST,
    addGroupToAssignment
  );
}

function* watchEditAssignmentLecturer() {
  yield takeLatest(
    courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST,
    editAssignmentLecturer
  );
}

function* watchRemoveLecturersFromAssignment() {
  yield takeLatest(
    courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST,
    removeLecturersFromAssignment
  );
}

function* watchGetAssignmentCourses() {
  yield takeLatest(
    courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_REQUEST,
    getAssignmentCourses
  );
}

function* watchAddEditCourseAssignmentResultCategory() {
  yield takeLatest(
    courseAssignmentActions.EDITING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST,
    editCourseAssignmentResultCategories
  );
}

export default [
  fork(watchGetDepartmentProgrammes),
  fork(watchAssignLecturersToCourse),
  fork(watchGetContextAssignments),
  fork(watchAddLecturersToAssignment),
  fork(watchAddGroupToAssignment),
  fork(watchEditAssignmentLecturer),
  fork(watchGetAssignmentCourses),
  fork(watchRemoveLecturersFromAssignment),
  fork(watchAddEditCourseAssignmentResultCategory),
  fork(watchGetSubmittedResults),
  fork(watchUnSubmitResults),
  fork(watchMakeResultsProvisional),
  fork(watchFetchProvisionalResults),
  fork(watchGetHODStatistics),
  fork(watchGetFacultyAnalytics),
  fork(watchGetQuatecResults),
  fork(watchGetQuatecAnalytics),
  fork(watchSubmitResultsToQuatec),
  fork(watchSubmitResultToFaculty),
  fork(watchGetSenateResults),
  fork(watchSubmitResultsToSenate),
  fork(watchGetCourseUnitResultApprovals),
  fork(watchGetProgrammeResultApprovals),
  fork(watchGetLecturerCategoriesByAcademicYear),
  fork(watchCreateLecturerCategory),
  fork(watchEditLecturerCategory),
  fork(watchDeleteLecturerCategory),
  fork(watchCopyLecturerCategories),
  fork(watchEditCourseAssignmentGroup),
  fork(watchDeleteCourseAssignmentGroup),
  fork(watchGetDepartmentApprovalResults),
  fork(watchGetProgrammeResultsReport),
  fork(watchReversePublishedResults),
];
